<template>
  <tr class="smart-table__row">
    <td class="smart-table__cell" data-column-id="favorite">
      <i class="icon-star icon-star--filled" role="button" tabindex="0"></i>
    </td>
    <td class="smart-table__cell" data-column-id="pair">
      <span>{{pair}}</span>
    </td>
    <td class="smart-table__cell text-right" data-column-id="price">
      <span>{{data.price}}</span>
    </td>
    <td class="smart-table__cell text-right" data-column-id="change" :class="{'minus': data.change < 0,'plus': data.change > 0,}">
      <span>{{data.change}}</span>%
    </td>
    <td class="smart-table__cell text-right" data-column-id="volume">
      <span>{{data.volume}}</span>
    </td>
  </tr>
</template>

<script>
const cfg = {
  coin: 'REND',
  base: 'USDT',
};

export default {
  name: 'SwapPair',
  props: ['data'],
  computed: {
    pair() {
      return this.data.symbol + '/' + cfg.base;
    },
    url() {
      return '#!/' + cfg.name + '/' + this.data.symbol;
    },
  },
}
</script>

<style scoped>

</style>
