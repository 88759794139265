<template>
  <main class="main">
    <div class="content" style="margin-top: 0.5rem; margin-bottom: 3.0rem;">
      <div class="container content__container--mw100">
        <div class="content__columns">
          <!--watchlist.begin-->
          <div class="content__column content__column--watchlist">
            <div class="box watchlist">
              <div class="tabs">
                <div class="tabs__head">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <button class="nav-link active">{{$t('alltitle')}}</button>
                    </li>
                    <!--<li class="nav-item">
                      <button class="nav-link">USDT</button>
                    </li>-->
                  </ul>
                  <!--<button class="btn btn-edit watchlist__btn-edit"><i class="fas fa-ellipsis-v"></i></button>-->
                </div>
                <div class="tab-content">
                  <div class="tab-pane fade show active rel">
                    <div class="form-search">
                      <input type="search" v-model="search" class="form-search__control"
                             :placeholder="$t('symbol')" required>
                      <button class="btn form-search__button"><i class="fas fa-search"></i></button>
                    </div>
                    <div class="smart-table smart-table--watchlist">
                      <table class="table smart-table__table">
                        <thead class="smart-table__thead">
                          <tr class="smart-table__row">
                            <th class="smart-table__cell">
                              <i class="icon-star" role="button" tabindex="0"></i>
                            </th>
                            <th class="smart-table__cell">
                              <div class="smart-table__sort-by">{{$t('pair')}}<span>↑</span>↓</div>
                            </th>
                            <th class="smart-table__cell text-right">
                              <div class="smart-table__sort-by">{{$t('price')}}<span>↑</span>↓</div>
                            </th>
                            <th class="smart-table__cell text-right">
                              <div class="smart-table__sort-by">{{$t('change')}}<span>↑</span>↓</div>
                            </th>
                            <th class="smart-table__cell text-right">
                              <div class="smart-table__sort-by">{{$t('volume')}}<span>↑</span>↓</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="smart-table__tbody scrollbar">
                          <template style="display: contents">
                            <swap-pair v-for="(item, index) in coins.feed" :data="item" :key="`swapPair-${index}`"></swap-pair>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--watchlist.end-->

          <!--candlechart.begin-->
          <div class="content__column content__column--candlechart">
            <div class="box gbox">
              <div id="tv_chart_container"></div>
            </div>
          </div>
          <!--candlechart.end-->

          <!--orderbook.begin-->
          <div class="content__column content__column--orderbook">
            <div class="box orderbook">
              <div class="orderbook__head">
                <h2 class="orderbook__title">{{$t('swaps')}}</h2>
                <div class="select orderbook__select">
                  <select class="select__control js-choice" name="exchangeGlassValue"
                          @change="handleChangeGlassFormat"
                          v-model="glassFormat" style="background-color: transparent;border: 0px;color: #676F9D;">
                    <option value="0.000001">0.000001</option>
                    <option value="0.00001">0.00001</option>
                    <option value="0.0001">0.0001</option>
                    <option value="0.001">0.001</option>
                    <option value="0.01">0.01</option>
                  </select>
                </div>
                <div class="orderbook__tabs-list">
                  <ul class="nav nav-tabs" id="tabOrderbook">
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': glassType === 'red'}"
                              @click="glassType = 'red'; swapTableScrollBottom('bottom')"
                              data-bs-toggle="tab" data-bs-target="#tabOrderbook1" type="button"
                              aria-selected="true">
                        <svg class="icon-circle"><use xlink:href="#i-circle-red"></use></svg>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': glassType === 'all'}"
                              @click="glassType = 'all'; swapTableScrollBottom('middle')"
                              data-bs-toggle="tab" data-bs-target="#tabOrderbook3"
                              type="button" aria-selected="false">
                        <svg class="icon-circle"><use xlink:href="#i-circle-red-green"></use></svg>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': glassType === 'green'}"
                              @click="glassType = 'green'; swapTableScrollBottom('top')"
                              data-bs-toggle="tab" data-bs-target="#tabOrderbook2" type="button"
                              aria-selected="false">
                        <svg class="icon-circle"><use xlink:href="#i-circle-green"></use></svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content">
                <div class="tab-pane fade show active" id="tabOrderbook1">

                  <div class="smart-table smart-table--orderbook">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                        <tr class="smart-table__row">
                          <th class="smart-table__cell">{{$t('price')}} USDT </th>
                          <th class="smart-table__cell text-right">{{$t('qty')}} REND</th>
                          <th class="smart-table__cell text-right">{{$t('total')}} USDT</th>
                        </tr>
                      </thead>
                      <tbody id="swapGlassTable" class="smart-table__tbody scrollbar">
                        <template style="display: contents" v-if="glassType==='red' || glassType==='all'">
                          <swap-glass v-for="(item,index) in ask" :data="item" :glass-format="glassFormat" :key="`swapGlass-${index}`"></swap-glass>
                        </template>
                        <tr class="smart-table__row" id="current-lot-price-row">
                          <td class="smart-table__cell" data-column-id="lastprice">
                            <span><i class="fas fa-chart-bar"></i>&nbsp;{{$t('currentprice')}}</span>
                          </td>
                          <td class="smart-table__cell text-right" data-column-id="price">
                            <span class="minus">{{lot.currentprice}} {{symbol.base}}</span>
                          </td>
                          <td class="smart-table__cell text-right" data-column-id="difference">
                            <span>{{lot.diff}}%</span>
                          </td>
                        </tr>
                        <template style="display: contents" v-if="glassType==='green' || glassType==='all'">
                          <swap-glass v-for="(item,index) in bid" :data="item" :glass-format="glassFormat" :key="`swapGlass2-${index}`"></swap-glass>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--orderbook.end-->

          <!--tradehistory.begin-->
          <div class="content__column content__column--tradehistory">
            <div class="box tradehistory">

              <div class="tabs">
                <div class="tabs__head">
                  <ul class="nav nav-tabs" id="tabTradehistory">
                    <li class="nav-item" v-for="(item, index) in tab.history.items" :key="`historyItems-${index}`">
                      <button class="nav-link" type="button"
                              :class="{ active: tab.history.active === index }"
                              @click="showTab('history', index)">
                        {{ $t(tab.history.items[index]) }}
                      </button>
                    </li>
                  </ul>
                  <div class="tabs__controls d-flex align-items-center">
                    <label class="toggle d-flex align-items-center me-3">
                      <input type="checkbox" v-model="hidepairs" class="toggle__control sr-only">
                      <span class="toggle__text me-2">{{$t('hidepairs')}}</span>
                      <span class="toggle__icon"></span>
                    </label>
                    <button class="btn-fullscreen" @click="historyFullScreen()">
                      <svg class="btn__icon"><use xlink:href="#i-fullscreen"></use></svg>
                    </button>
                  </div>
                </div>
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tabTradehistory1">
                    <div class="smart-table smart-table--tradehistory">
                      <table class="table smart-table__table">
                        <thead class="smart-table__thead">
                          <tr class="smart-table__row">
                            <th class="smart-table__cell" data-column-id="pair">
                              <div class="smart-table__sort-by">{{$t('pair')}}</div>
                            </th>
                            <th class="smart-table__cell" data-column-id="buysell">
                              <div class="smart-table__circles">
                                <svg class="smart-table__circle"><use xlink:href="#i-circle-red"></use></svg>
                                <svg class="smart-table__circle">
                                  <use xlink:href="#i-circle-green"></use>
                                </svg>
                                <svg class="smart-table__circle">
                                  <use xlink:href="#i-circle-red-green"></use>
                                </svg>
                              </div>
                            </th>
                            <th class="smart-table__cell" data-column-id="type">
                              <div class="smart-table__sort-by">{{$t('type')}}</div>
                            </th>
                            <th class="smart-table__cell" data-column-id="date">
                              <div class="smart-table__sort-by">{{$t('stamp')}}</div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="count">
                              <div class="smart-table__sort-by">{{$t('qty')}}</div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="commission">
                              <div class="smart-table__sort-by">{{$t('commission')}}</div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="price">
                              <div class="smart-table__sort-by">{{$t('price')}}</div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="total">
                              <div class="smart-table__sort-by">{{$t('amount')}}</div>
                            </th>
                            <th class="smart-table__cell" data-column-id="status">
                              <div class="d-flex justify-content-between">
                                <span class="smart-table__status">{{$t('status')}}</span>
                                <div class="smart-table__del me-1">
                                  {{$t('cancel_all')}}
                                  <span class="smart-table__close"></span>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="smart-table__tbody scrollbar" v-show="tab.history.active === 0">
                          <template style="display: contents">
                            <swap-lot v-for="(item,index) in history.active" :data="item" :key="`swapLotActive-${index}`"></swap-lot>
                          </template>
                        </tbody>
                        <tbody class="smart-table__tbody scrollbar" v-show="tab.history.active === 1">
                          <template style="display: contents">
                            <swap-lot v-for="(item,index) in history.user" :data="item" :key="`swapLotUser-${index}`"></swap-lot>
                          </template>
                        </tbody>
                        <tbody class="smart-table__tbody scrollbar" v-show="tab.history.active === 2">
                          <template style="display: contents">
                            <swap-lot v-for="(item,index) in history.active_and_history" :data="item" :key="`swapLotUserAndHistory-${index}`"></swap-lot>
                          </template>
                        </tbody>
                        <tbody class="smart-table__tbody scrollbar" v-show="tab.history.active === 3">
                          <template style="display: contents">
                            <swap-lot v-for="(item,index) in history.all" :data="item" :key="`swapLotAll-${index}`"></swap-lot>
                          </template>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--tradehistory.end-->

          <!--createorder.begin-->
          <div class="content__column content__column--createorder">
            <div class="box createorder">
              <div class="tabs">
                <div class="tabs__head">
                  <ul class="nav nav-tabs" id="tabCreateorder">
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': lot.bid}"
                              type="button" aria-selected="true" @click="tabLot(true)">
                        <span class="plus">{{$t('buybtn')}}</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" v-bind:class="{'active': !lot.bid}"
                              type="button" aria-selected="false" @click="tabLot(false)">
                        <span class="minus">{{$t('sellbtn')}}</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                  <div class="tab-pane fade show active">

                    <div class="tabs">
                      <div class="tabs__head">
                        <ul class="nav nav-tabs mode-tabs">
                          <li class="nav-item">
                            <button class="nav-link active" type="button" aria-selected="true" @click="changeSwapMode('LIMIT', $event)">{{$t('lotlimit')}}</button>
                          </li>
                          <li class="nav-item">
                            <button class="nav-link" type="button" aria-selected="false" @click="changeSwapMode('MARKET', $event)">{{$t('lotmarket')}}</button>
                          </li>
                          <!--<li class="nav-item">
                            <button class="nav-link" data-bs-toggle="tab" type="button" aria-selected="false">{{$t('lotstoplimit')}}</button>
                          </li>-->
                        </ul>
                      </div>
                      <div class="tab-content">

                        <div class="tab-pane fade show active" id="tabCreateorderBuy1" v-if="swapMode == 'LIMIT'">
                          <!--userBalance.start-->
                          <div class="d-flex justify-content-between my-1 ts-8">
                            <div class="p-1">{{$t('balance')}}</div>
                            <div class="p-1"><i class="fas fa-wallet"></i>
                              <span v-show="lot.bid">
                                {{Number(balance.base).toFixed(numberFormat[glassFormat])}} {{symbol.base}}
                              </span>
                              <span v-show="!lot.bid">
                                {{Number(balance.coin).toFixed(numberFormat[glassFormat])}} {{symbol.coin}}
                              </span>
                            </div>
                          </div>
                          <!--user balance.end-->
                          <!--step
                          <div class="d-flex justify-content-between align-items-center my-1">
                            <div class="flex-fill"><h4 class="ts-8 my-1 p-1">{{$t('lotstep')}}</h4></div>
                            <div>
                              <select class="select__control bold" v-model="step">
                                <option v-for="item in steps" :value="item">{{item}}</option>
                              </select>
                            </div>
                          </div>-->
                          <!--step select.end-->
                          <!--form createorder.begin-->
                            <div class="form__item">
                              <div class="row align-items-center">
                                <div class="col-3">
                                  <label class="form__label" for="createorderBuyLimitPrice">
                                    {{$t('price')}}
                                  </label>
                                </div>
                                <div class="col-9">
                                  <div class="count">
                                    <button class="count__btn count__btn-minus" type="button" @click="calcPrice(-step, $event)">
                                      <svg class="count__icon"><use xlink:href="#i-minus"></use></svg>
                                    </button>
                                    <div class="count__block">
                                      <input type="text" class="count__input" min="0"
                                             v-model="lot.price"
                                             :placeholder="$t('price')" @keyup="calcPrice(0, $event)">
                                      <span class="count__cur">{{symbol.base}}</span>
                                      <span class="count__line"></span>
                                    </div>
                                    <button class="count__btn count__btn-plus" type="button"
                                            @click="calcPrice(step, $event)">
                                      <svg class="count__icon"><use xlink:href="#i-plus"></use></svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form__item">
                              <div class="row align-items-center">
                                <div class="col-3">
                                  <label class="form__label" for="createorderBuyLimitCount">{{$t('qty')}}</label>
                                </div>
                                <div class="col-9">
                                  <div class="count">
                                    <button class="count__btn count__btn-minus" type="button" @click="calcCoin(-step)">
                                      <svg class="count__icon"><use xlink:href="#i-minus"></use></svg>
                                    </button>
                                    <div class="count__block">
                                      <input type="text" id="createorderBuyLimitCount" class="count__input" min="0"
                                             v-model="lot.value"
                                             @change="lot.value = Number(lot.value).toFixed(numberFormat[glassFormat])"
                                             :placeholder="$t('valueph')" @keyup="calcBase(0)">
                                      <span class="count__cur">{{symbol.coin}}</span>
                                      <span class="count__line"></span>
                                    </div>
                                    <button class="count__btn count__btn-plus" type="button" @click="calcCoin(step)">
                                      <svg class="count__icon"><use xlink:href="#i-plus"></use></svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form__item">
                              <div class="row align-items-center">
                                <div class="col-3">
                                  <label class="form__label" for="createorderBuyLimitSum">{{$t('amount')}}</label>
                                </div>
                                <div class="col-9">
                                  <div class="count">
                                    <button class="count__btn count__btn-minus" type="button" @click="calcBase(-step)">
                                      <svg class="count__icon"><use xlink:href="#i-minus"></use></svg>
                                    </button>
                                    <div class="count__block">
                                      <input type="text" id="createorderBuyLimitSum" class="count__input" min="0"
                                             v-model="lot.amount"
                                             @change="lot.amount = Number(lot.amount).toFixed(numberFormat[glassFormat])"
                                             :placeholder="$t('amountph')" @keyup="calcCoin(0)">
                                      <span class="count__cur">{{symbol.base}}</span>
                                      <span class="count__line"></span>
                                    </div>
                                    <button class="count__btn count__btn-plus" type="button" @click="calcBase(step)">
                                      <svg class="count__icon"><use xlink:href="#i-plus"></use></svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form__item visually-hidden">
                              <div class="range form__range">
                                <input type="range" class="range__control" name="range" step="25" min="0" max="100" value="0">
                              </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between my-1 ts-8">
                              <div class="xbtn"><span class="hand" @click="askAmount(25)">25%</span></div>
                              <div class="xbtn"><span class="hand" @click="askAmount(50)">50%</span></div>
                              <div class="xbtn"><span class="hand" @click="askAmount(75)">75%</span></div>
                              <div class="xbtn"><span class="hand" @click="askAmount(100)">100%</span></div>
                            </div>
                            <div class="xrange">
                              <input type="range" @change="changeRange($event)" :min="minRange" :max="maxRange" value="0" step="0.1">
                            </div>
                            <div class="xrange-vals is-flex" v-if="lot.bid == true">
                              <div>{{ minRange }}  {{symbol.base}}</div>
                              <div>{{ maxRange }}  {{symbol.base}}</div>
                            </div>
                            <div class="xrange-vals is-flex" v-if="lot.bid == false">
                              <div>{{ minRange }}  {{symbol.coin}}</div>
                              <div>{{ maxRange }}  {{symbol.coin}}</div>
                            </div>
                            <button class="btn btn-success w-100"
                                    :disabled="disableAddSwap"
                                    v-show="lot.bid"
                                    @click="addSwap(true)">
                              {{$t('buybtn')}} {{symbol.coin}}
                            </button>
                            <button class="btn btn-danger w-100"
                                    :disabled="disableAddSwap"
                                    v-show="!lot.bid"
                                    @click="addSwap(false)">
                              {{$t('sellbtn')}} {{symbol.coin}}
                            </button>
                          <!--form createorder.end-->

                        </div>

                        <div class="tab-pane fade show active" id="tabCreateorderBuy2" v-if="swapMode == 'MARKET'">

                          <div v-if="allowMarket">

                            <div class="form__item" v-if="lot.bid == true">
                                <div class="row align-items-center">
                                  <div class="col-3">
                                    <label class="form__label" for="createorderBuyLimitSum">{{$t('price')}}</label>
                                  </div>
                                  <div class="col-9">
                                    <div class="count">
                                      <div class="count__block">
                                        <input type="text" id="" class="count__input" min="0" style="font-weight: bold" :value="this.$t('market_usdt')" readonly>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            <div class="form__item" v-if="lot.bid == true">
                                <div class="row align-items-center">
                                  <div class="col-3">
                                    <label class="form__label" for="createorderBuyLimitSum">{{$t('amount')}}</label>
                                  </div>
                                  <div class="col-9">
                                    <div class="count">
                                      <button class="count__btn count__btn-minus" type="button" @click="calcBase(-step)">
                                        <svg class="count__icon"><use xlink:href="#i-minus"></use></svg>
                                      </button>
                                      <div class="count__block">
                                        <input type="text" id="createorderBuyLimitSum" class="count__input" min="0"
                                               v-model="lot.amount"
                                               @change="lot.amount = Number(lot.amount).toFixed(numberFormat[glassFormat])"
                                               :placeholder="$t('amountph')" @keyup="calcCoin(0)">
                                        <span class="count__cur">{{symbol.base}}</span>
                                        <span class="count__line"></span>
                                      </div>
                                      <button class="count__btn count__btn-plus" type="button" @click="calcBase(step)">
                                        <svg class="count__icon"><use xlink:href="#i-plus"></use></svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            <div class="form__item" v-if="lot.bid == false">
                                <div class="row align-items-center">
                                  <div class="col-3">
                                    <label class="form__label" for="createorderBuyLimitCount">{{$t('qty')}}</label>
                                  </div>
                                  <div class="col-9">
                                    <div class="count">
                                      <button class="count__btn count__btn-minus" type="button" @click="calcCoin(-step)">
                                        <svg class="count__icon"><use xlink:href="#i-minus"></use></svg>
                                      </button>
                                      <div class="count__block">
                                        <input type="text" id="createorderBuyLimitCount" class="count__input" min="0"
                                               v-model="lot.value"
                                               @change="lot.value = Number(lot.value).toFixed(numberFormat[glassFormat])"
                                               :placeholder="$t('valueph')" @keyup="calcBase(0)">
                                        <span class="count__cur">{{symbol.coin}}</span>
                                        <span class="count__line"></span>
                                      </div>
                                      <button class="count__btn count__btn-plus" type="button" @click="calcCoin(step)">
                                        <svg class="count__icon"><use xlink:href="#i-plus"></use></svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="xrange" v-if="lot.bid == true">
                                <input type="range" @change="changeMarketRange($event)" :min="0" :max="maxMarketUsdt" value="0" step="0.1">
                              </div>

                              <div class="xrange" v-if="lot.bid == false">
                                <input type="range" @change="changeMarketRange($event)" :min="0" :max="maxMarketRendNoBid" value="0" step="0.1">
                              </div>

                              <div class="xrange-vals is-flex" v-if="lot.bid == true">
                                <div>0 {{symbol.base}}</div>
                                <div>{{ maxMarketUsdt }}  {{symbol.base}}</div>
                              </div>
                              <div class="xrange-vals is-flex" v-if="lot.bid == false">
                                <div>0  {{symbol.coin}}</div>
                                <div>{{ maxMarketRendNoBid }}  {{symbol.coin}}</div>
                              </div>

                              <button class="btn btn-success w-100"
                                      :disabled="disableAddSwap"
                                      v-show="lot.bid"
                                      @click="addMarket(true)">
                                {{$t('buybtn')}} {{symbol.coin}}
                              </button>
                              <button class="btn btn-danger w-100"
                                      :disabled="disableAddSwap"
                                      v-show="!lot.bid"
                                      @click="addMarket(false)">
                                {{$t('sellbtn')}} {{symbol.coin}}
                              </button>

                            </div>

                            <div v-if="!allowMarket && lot.bid == 0">
                              {{$t('swap_sorry_1')}}
                            </div>

                            <div v-if="!allowMarket && lot.bid == 1">
                              {{$t('swap_sorry_2')}}
                            </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--createorder.end-->

        </div>

      </div>
    </div>

  </main>
</template>

<script>

import axios from 'axios';
import SwapPair from '@/components/SwapPair/SwapPair';
import SwapGlass from '@/components/SwapGlass/SwapGlass';
import SwapLot from '@/components/SwapLot/SwapLot';
import {readUser, readUserFp} from '@/store/main/getters';
import {commitToggleModal} from '@/store/main/mutations';
import { DataCube } from 'trading-vue-js';
import Data from './data.json';
import SimpleOverlay from './overlays/SimpleOverlay.vue';
const cfg = {
  coin: 'REND',
  base: 'USDT',
};

export default {
  components: {
    SwapPair,
    SwapGlass,
    SwapLot
  },
  name: "Swap",
  data() {
    return {
      candlesRange: 86400,
      tradingChart: new DataCube(),
      overlays: [SimpleOverlay],
      colors: {
        back: '#1A1149',
        grid: '#3e3e3e',
        text: '#676F9D',
        cross: '#dd64ef',
        candle_dw: '#e54077',
        wick_dw: '#e54077',
        tvTitle: '#ffffff',
      },
      glassFormat: '0.000001',
      glassType: 'all',
      auth: false,
      symbol: {
        base: cfg.base,
        coin: cfg.coin,
      },
      coins: {
        feed: [],
        page: 1,
      },
      search: '',
      balance: {
        base: 0,
        coin: 0,
      },
      numberFormat: {
        '0.000001': 6,
        '0.00001': 5,
        '0.0001': 4,
        '0.001': 3,
        '0.01': 2,
      },
      bid: [
          // {
          //   amount: glassFormat"11.600000",
          //   bid: "1",
          //   closed: "0",
          //   coin: "2",
          //   id: "172",
          //   price: "1.360000",
          //   stamp: "1619355867",
          //   uid: "5",
          //   value: "10.000000",
          // },
        ],
      ask: [
          // {
          //   amount: "11.600000",
          //   bid: "0",
          //   closed: "0",
          //   coin: "2",
          //   id: "172",
          //   price: "1.160000",
          //   stamp: "1619355867",
          //   uid: "5",
          //   value: "10.000000",
          // },
        ],
      history: {
        active: [],
        user: [],
        all: [],
        active_and_history: [],
      },
      lot: {
        amount: 0,
        value: 0,
        price: 0,
        bid: true,
        currentprice: 0,
        diff: 0,
      },
      step: 1,
      steps: [ 0.1, 1, 10 ],
      disabled: false,
      hidepairs: true,
      disableAddSwap: false,
      walletBalance: {},
      tab: {
        history: {
          active: 0,
          items: ['userlots', 'userlotshistory',  'userlotsactiveandhistory', 'lotshistory'],
        },
      },
      chart: {},
      minBuyPriceUSDT: 0,
      maxBuyPriceUSDT: 0,
      minRange: 0,
      maxRange: 0,
      tradeWidth: 0,
      swapMode: 'LIMIT',
      maxMarketRendBid: 0,
      maxMarketRendNoBid: 0,
      maxMarketUsdt: 0,
      maxMarketUsdtNoBid: 0,
      minMarketRange: 0,
      maxmarketRange: 0,
      allowMarket: false
    };
  },
  computed: {
    user() {
      return readUser(this.$store);
    },
  },
  watchers: {
  },
  methods: {

    checkAllowMarket() {
      this.allowMarket = false;
      if(this.maxMarketRendNoBid > 0 && this.lot.bid) {
        this.allowMarket = true;
      }
      if(this.maxMarketRendBid > 0 && !this.lot.bid) {
        this.allowMarket = true;
      }
    },

    changeSwapMode(mode, event) {
      this.swapMode = mode;
      document.querySelector('.mode-tabs .nav-link.active').classList.remove('active');
      event.target.classList.add('active');
      this.checkAllowMarket();
      $('.xrange > input').val(0);
      this.lot.amount = 0;
      this.lot.value = 0;
    },

    tabLot(mode) {
      //$('.xrange > input').val(0);
      this.lot.bid = mode;
      if(mode == true) {
        this.maxRange = this.balance.base;
      } else {
        this.maxRange = this.balance.coin;
      }
      this.rangeProccess();
      this.checkAllowMarket();
      if(this.swapMode == 'MARKET') {
        $('.xrange > input').val(0);
        this.lot.amount = 0;
        this.lot.value = 0;
      }
    },

    changeRange(event) {
      if (this.lot.bid) {
        this.lot.amount = parseFloat(event.target.value);
        this.calcCoin();
      }
      else {
        this.lot.value = parseFloat(event.target.value);
        this.calcBase();
      }
    },

    changeMarketRange(event) {
      if (this.lot.bid) {
        this.lot.amount = parseFloat(event.target.value);
      }
      else {
        this.lot.value = parseFloat(event.target.value);
      }
    },

    historyFullScreen() {
      $('.box.tradehistory').toggleClass('full');
      $('html, body').toggleClass('overflow');
    },

    handleChangeGlassFormat() {
      localStorage.glassFormat = this.glassFormat;

      this.lot.price = Number(this.lot.price).toFixed(this.numberFormat[this.glassFormat]);
      this.lot.value = Number(this.lot.value).toFixed(this.numberFormat[this.glassFormat]);
      this.lot.amount = Number(this.lot.amount).toFixed(this.numberFormat[this.glassFormat]);
    },
    checkAuth() {
      if (this.user.hash) return this.auth = true;
      commitToggleModal(this.$store, 'login');
      return false;
    },
    getCoins() {
      this.coins.feed = [];
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method: 'getCoins',
            data: {page: this.coins.page, fp: readUserFp(this.$store), uid: this.user.uid}},
          {headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.data.code !== 2||typeof(r.data.data) !== 'object') return false;
        this.coins.feed = r.data.data.coins || [];
      });
    },
    setLotFromGlass(price, value, amount) {
      this.lot.price = Number(price).toFixed(this.numberFormat[this.glassFormat]);
      this.lot.value = Number(value).toFixed(this.numberFormat[this.glassFormat]);
      this.lot.amount = Number(amount).toFixed(this.numberFormat[this.glassFormat]);
    },
    async getFeed() {
      this.bid = this.ask = this.mine = [];
      this.balance = {base:0,coin:0};
      const r = await axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method: 'getFeed', data: {symbol: this.symbol.coin, fp:readUserFp(this.$store), uid:this.user.uid}},
          {headers: {'Authorization': 'Bearer ' + this.user.hash}});
      console.log(r.data);
      if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
      if (r.data.code !== 2 || typeof(r.data.data) !== 'object') return false;
      this.balance = r.data.data.balance || {base: 0, coin: 0};
      console.log('get wallet: ', this.balance)
      this.bid = r.data.data.bid || [];
      this.ask = r.data.data.ask || [];

      

      this.lot.price = (this.ask.length) ? this.ask[this.ask.length-1].price : 0;
      this.lot.price = Number(this.lot.price).toFixed(this.numberFormat[this.glassFormat]);
      this.lot.currentprice =  (this.bid.length) ? this.bid[0].price : 0;
      this.lot.diff = (this.bid.length&&this.ask.length) ? ((this.ask[this.ask.length-1].price-this.bid[0].price)/this.ask[this.ask.length-1].price*100).toFixed(4) : 0;

      var lastAsk = this.ask[this.ask.length-1],
          firstAsk = this.ask[0];
      //console.log('FIRST', firstAsk);
      this.minBuyPriceUSDT = (parseFloat(lastAsk.price)-(parseFloat(lastAsk.price)*0.3)).toFixed(3);
      this.maxBuyPriceUSDT = (parseFloat(firstAsk.price)+(parseFloat(firstAsk.price)*0.3)).toFixed(3);
      console.log('MAXBUY', this.maxBuyPriceUSDT);
      this.lot.price  = this.minBuyPriceUSDT;

      this.maxRange = this.balance.base;

      var marketBidSumm = 0,
          marketNoBidSumm = 0;

      $.each(this.bid, function(i, val){
        //console.log(val);
        marketNoBidSumm = parseFloat(marketNoBidSumm) + parseFloat(val.value);
      })

      $.each(this.ask, function(i, val){
        //console.log(val);
        marketBidSumm = parseFloat(marketBidSumm) + parseFloat(val.value);
      })
      this.maxMarketRendBid = marketBidSumm.toFixed(this.numberFormat[this.glassFormat]);
      this.maxMarketRendNoBid = this.balance.coin;

      this.maxMarketRange = this.balance.base;
      this.maxMarketUsdt = this.balance.base;

      //console.log('base', this.balance);


      //this.getChartData('line');
      await this.swapTableScrollBottom('middle');
    },
    swapTableScrollBottom(side) {
      setTimeout(() => {
        console.log('scrolling', side);
        let element = document.getElementById('swapGlassTable');
        let currentPrice = document.getElementById('current-lot-price-row');
        if (side === 'bottom') {
          element.scrollTop = element.scrollHeight;
        } else if (side === 'top') {
          element.scrollTop = 0;
        } else {
          element.scrollTop = element.scrollHeight / 3;
        }
      }, 1000);
    },
    getLots() {
      if (!this.checkAuth()) return false;
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',{method: 'getLots',
          data: {symbol: (!this.hidepairs) ? this.symbol.coin : false, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.data.code !== 2||typeof(r.data.data) !== 'object') return false;
        this.history.active = r.data.data.lots || [];
      });
    },
    getHistory(mine=true) {
      if (mine&&!this.checkAuth()) return false;
      this.$noty.info(this.$t('loading'), { timeout: 4000, progressBar: true });
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',{method:'getHistory',
          data:{symbol: (!this.hidepairs)? this.symbol.coin : false, mine:mine, fp: readUserFp(this.$store), uid: this.user.uid}},{headers:{'Authorization':'Bearer '+this.user.hash}}).then(r=>{
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.data.code !== 2 || typeof(r.data.data)!='object') return false;
        if (mine) this.history.user = r.data.data.history || [];
        else this.history.all = r.data.data.history || [];
      });
    },
    async getLotsAndHistory() {
      if (!this.checkAuth()) return false;
      this.$noty.info(this.$t('loading'), { timeout: 4000, progressBar: true });
      const lotsResponse = await axios.post(this.$store._modules.root.state.main.API_URL+'/swap',{method: 'getLots',
          data: {symbol: (!this.hidepairs) ? this.symbol.coin : false, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}})

      if (lotsResponse.data.code === 2 && typeof(lotsResponse.data.data) === 'object') {
        this.history.active_and_history = lotsResponse.data.data.lots || [];
      }

      const historyResponse = await axios.post(this.$store._modules.root.state.main.API_URL+'/swap',{method:'getHistory',
          data:{symbol: (!this.hidepairs)? this.symbol.coin : false, mine:true, fp: readUserFp(this.$store), uid: this.user.uid}},{headers:{'Authorization':'Bearer '+this.user.hash}})

      if (historyResponse.data.code === 2 && typeof(historyResponse.data.data) === 'object') {
        this.history.active_and_history = this.history.active_and_history.concat(historyResponse.data.data.history || []);
      }
    },
    getBalance() {
      if (!this.checkAuth()) return false;
      this.walletBalance = {};
      axios.post(this.$store._modules.root.state.main.API_URL+'/wallet',
          {method: 'getBalance', data:{symbol: 'usdt', fp: readUserFp(this.$store), uid: this.user.uid}},
          {headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong'))+(r.data.data.msg||''), { timeout: 4000, progressBar: true });
        if (r.data.code !== 2 || typeof(r.data.data) !== 'object') return false;
        // this.coin = r.data.data.coin;
        this.walletBalance = r.data.data.balance;
      });
    },

    addMarket(bid = true) {

      if (!this.checkAuth()) return false;

      if(this.lot.amount == 0 && this.lot.bid) {
        this.$noty.error(this.$t('insert_sum_usdt'), { timeout: 2000, progressBar: true });
        return;
      }

      if(this.lot.value == 0 && !this.lot.bid) {
        this.$noty.error(this.$t('insert_sum_rend'), { timeout: 2000, progressBar: true });
        return;
      }

      //console.log(this.lot.value+' : '+this.maxMarketRendBid);

      if(bid && parseFloat(this.lot.amount) > parseFloat(this.maxMarketUsdt)) {
        this.$noty.error(this.$t('max_sum_buy')+' — '+this.maxMarketUsdt+' USDT', { timeout: 2000, progressBar: true });
        return;
      }
      if(!bid && parseFloat(this.lot.value) > parseFloat(this.maxMarketRendNoBid)) {
        this.$noty.error(this.$t('max_sum_sale')+' — '+this.maxMarketRendNoBid+' REND', { timeout: 2000, progressBar: true });
        return;
      }

      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method:'addMarket',data:{symbol: this.symbol.coin, lot: this.lot, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization': 'Bearer ' + this.user.hash}}).then(r=>{
            console.log(r.data);
        switch(r.data.code) {
          //case 0: app.signOut();break;
          case 1:
            this.$noty.error(this.$t('smthwrong'), { timeout: 2000, progressBar: true });
            break;
          case 2:
            this.$noty.success(this.$t('order_success'), { timeout: 3000, progressBar: true });
            this.getFeed();
            this.lot.value = 0;
            break;
          case 3:
          case 4:
            this.$noty.error(r.data.data.msg, { timeout: 2000, progressBar: true });
            break;
        }
      });

    },

    addSwap(bid=true) {
      if (!this.checkAuth()) return false;
      var lot = {value:parseFloat(this.lot.value||0),price:parseFloat(this.lot.price||0),bid:bid};
      lot.amount = parseFloat((lot.value*lot.price).toFixed(4));
      if (lot.value <= 0 || lot.price <= 0 || lot.amount <= 0) return this.$noty.error(this.$t('wrongdata'), { timeout: 4000, progressBar: true });
      if ((!bid && lot.value > parseFloat(this.balance.coin)) || (bid && lot.amount > parseFloat(this.balance.base))) return this.$noty.error(this.$t('lowbalance'), { timeout: 4000, progressBar: true });
      this.disableAddSwap = true;
      this.$noty.info(this.$t('sending'), { timeout: 2000, progressBar: true });
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method:'addSwap',data:{symbol: this.symbol.coin, lot: lot, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization': 'Bearer ' + this.user.hash}}).then(r=>{
        switch(r.data.code) {
          //case 0: app.signOut();break;
          case 1:
            this.$noty.error(this.$t('smthwrong'), { timeout: 2000, progressBar: true });
            break;
          case 2:
            this.$noty.success(this.$t('lotadded'), { timeout: 3000, progressBar: true });
            this.getFeed();
            this.showTab('history',0);
            this.lot.amount = 0;
            this.calcCoin(0);
            break;
          case 3:
          case 4:
            this.$noty.error(r.data.data.msg, { timeout: 2000, progressBar: true });
            break;
        }
        this.disableAddSwap = false;
      });
    },
    delSwap(id) {
      if (!this.checkAuth()) return false;
      if (!/^\d+$/.test(id)) return this.$noty.error(this.$t('wrongdata'), { timeout: 4000, progressBar: true });
      this.$noty.info(this.$t('sending'), { timeout: 4000, progressBar: true });
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method: 'delSwap', data: {id: id, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization': 'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);
        //if (r.data.code==0) return app.signOut();
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.data.code === 2) {
          this.$noty.success(this.$t('deleted'), { timeout: 2000, progressBar: true });
          this.getFeed();
          this.showTab('history',0);
        }
      });
    },
    askAmount(percent) {
      if (this.lot.bid) {
        this.lot.amount = (parseFloat(this.balance.base||0) * percent / 100).toFixed(this.numberFormat[this.glassFormat]);
        this.calcCoin();
        $('.xrange > input').val(this.lot.amount);
      }
      else {
        this.lot.value = (parseFloat(this.balance.coin||0) * percent / 100).toFixed(this.numberFormat[this.glassFormat]);
        this.calcBase();
        $('.xrange > input').val(this.lot.value);
      }
      //console.log('LOT', this.lot.amount);
      
    },
    calcBase(change = 0) {
      this.rangeProccess();
      if(this.lot.amount < 1 && change < 0) return;
      if (change !== 0) {
        this.lot.amount = (parseFloat(this.lot.amount) + change).toFixed(this.numberFormat[this.glassFormat]);
        this.calcCoin(0);
      }
      else {
        this.lot.amount = (parseFloat(this.lot.value || 0) * parseFloat(this.lot.price || 0)).toFixed(this.numberFormat[this.glassFormat]);
      }
    },
    calcCoin(change = 0) {
      this.rangeProccess()
      if(this.lot.value < 1 && change < 0) return;
      if (change !== 0) {
        this.lot.value = (parseFloat(this.lot.value) + change).toFixed(this.numberFormat[this.glassFormat]);
        this.calcBase(0);
      }
      else {
        let price = parseFloat(this.lot.price || 0);
        if (price <= 0) return false;
        this.lot.value = (parseFloat(this.lot.amount) / price).toFixed(this.numberFormat[this.glassFormat]);
      }
      
    },
    rangeProccess() {
      console.log(this.lot);
      if (this.lot.bid) {
        if(this.lot.amount > 0) {
          $('.xrange > input').val(this.lot.amount);
        } else {
          $('.xrange > input').val(0);
        }
      }
      if (!this.lot.bid) {
        if(this.lot.value > 0) {
          $('.xrange > input').val(this.lot.value);
        } else {
          $('.xrange > input').val(0);
        }
      }
    },
    calcPrice(change = 0, event) {
      var key = event.keyCode || event.charCode;
      if(key == 8 || key == 46) return;
      if(this.lot.price < 1 && change < 0) return;
      /*if (change !== 0) {
        var newPrice = (parseFloat(this.lot.price)+change).toFixed(this.numberFormat[this.glassFormat]);
        if(newPrice > this.minBuyPriceUSDT) {
          this.lot.price = newPrice;
          this.calcCoin(0);
        }
      }
      else {
        this.calcBase();
        this.calcCoin();
      }*/
      //var newPrice = (parseFloat(this.lot.price)+change).toFixed(this.numberFormat[this.glassFormat]);
      //var newPrice = (parseFloat(this.lot.price)+change);
      var newPrice = this.lot.price;
      if(change > 0) {
        newPrice = (parseFloat(this.lot.price)+change);
      }
      if(newPrice < this.minBuyPriceUSDT && this.lot.bid) {
        //this.$noty.info('Минимальная цена — '+parseFloat(this.minBuyPriceUSDT).toFixed(this.numberFormat[this.glassFormat])+' USDT', { timeout: 2000, progressBar: true });
        //this.lot.price = parseFloat(this.minBuyPriceUSDT).toFixed(this.numberFormat[this.glassFormat]);
      }
      if(newPrice > this.maxBuyPriceUSDT && !this.lot.bid) {
        //this.$noty.info('Максимальная цена — '+parseFloat(this.maxBuyPriceUSDT).toFixed(this.numberFormat[this.glassFormat])+' USDT', { timeout: 2000, progressBar: true });
        //this.lot.price = parseFloat(this.minBuyPriceUSDT).toFixed(this.numberFormat[this.glassFormat]);
      }
      if(newPrice != NaN) {
        this.lot.price = newPrice;
        this.calcCoin(0);
        this.calcBase();
        this.calcCoin();
      }
    },
    showTab(t, n) {
      if (typeof(this.tab[t]) === 'undefined'||typeof(n)!='number') return false;
      this.tab[t].active = n;
      if (t === 'history') switch(n){
        case 0: this.getLots();break;
        case 1: this.getHistory(true);break;
        case 2: this.getLotsAndHistory();break;
        case 3: this.getHistory(false);break;
      }
    },
    updateAmount(type, up=true) {
      if (typeof(this[type]) !== 'object' || typeof(this[type].amount) === 'undefined') return false;
      this[type].amount = parseFloat(this[type].amount || 0);
      if (up) this[type].amount += this.step;
      else {
        if (this.step > this[type].amount) return false;
        this[type].amount -= this.step;
      }
      this[type].amount = this[type].amount.toFixed(6);
    },
    async createChart() {
      // this.chart =  { app: false, candles: {}, range: 3600, feed: [] };
      // let el = document.querySelector('#tradingview');
      // let pos = el.getBoundingClientRect();
      // this.chart.app = createChart('tradingview',{
      //   width: pos.width - 10, height: pos.height - 10,
      //   layout: { backgroundColor: '#1A1149', textColor: '#B5BCDA', fontSize: 10 },
      //   grid: {
      //       vertLines: {
      //       color: 'rgba(70, 130, 180, 0.5)',
      //       style: 1,
      //       visible: true,
      //     },
      //     horzLines: {
      //       color: 'rgba(70, 130, 180, 0.5)',
      //       style: 1,
      //       visible: true,
      //     },
      //   },
      //   localization: {
      //     priceFormatter: price => '$' + price.toFixed(4),
      //     priceScale: {
      //       position: 'left',
      //       mode: 2,
      //       autoScale: true,
      //       invertScale: false,
      //       alignLabels: false,
      //       borderVisible: false,
      //       borderColor: '#B5BCDA',
      //       scaleMargins: {
      //         top: 0.30,
      //         bottom: 0.25,
      //       },
      //     },
      //   },
      // });
      // this.chart.candles = this.chart.app.addCandlestickSeries({
      //   upColor: '#01B26A',
      //   downColor: '#FF5860',
      //   borderVisible: false,
      //   wickVisible: true,
      //   borderColor: '#676F9D',
      //   wickColor: '#B5BCDA',
      //   borderUpColor: '#01B26A',
      //   borderDownColor: '#FF5860',
      //   wickUpColor: '#01B26A',
      //   wickDownColor: '#FF5860',
      // });
      this.getChart();
    },

    reinitNewChart() {

      var interval = localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution');

      $('#tv_chart_container').remove();
      $('.content__column--candlechart .box').html('<div id="tv_chart_container"></div>');
      this.createNewChart(interval);
    },

    createNewChart(interval = '1W') {

      //delete(widget);

      //console.log('GO INIT');

      var widget = window.tvWidget = new TradingView.widget({
          library_path: "charting_library/",
          //debug: true, // uncomment this line to see Library errors and warnings in the console
          //fullscreen: true,
          symbol: 'REND',
          //intervals: ['1W', '1D', '4H', '1H', '15'],
          /*favorites: {
          intervals: ["1D", "2D", "1H", "2H", "15", "1W"],
          },*/
          interval: interval,
          container: "tv_chart_container",
          datafeed: new Datafeeds.UDFCompatibleDatafeed("https://myexchange.ai/api/datafeed"),
          //datafeed: new Datafeeds.UDFCompatibleDatafeed("https://api.kdo.name/api/datafeed"),
          locale: "ru",
          disabled_features: [],
          enabled_features: [],
          theme: "dark",
          height: 372,
          overrides: {
              "paneProperties.background": "#1A1149",
              "paneProperties.backgroundGradientStartColor": "#1A1149",
              "paneProperties.backgroundGradientEndColor": "#1A1149",
          },
          has_intraday: true
        });


      setTimeout(function(){

          var blockClick = false;

          $('#tv_chart_container iframe').contents().find('body .menu-b3Cgff6l.button-merBkM5y').bind("DOMSubtreeModified",function(){
            var lastTime = localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution');
            if(!blockClick) {
              //console.log('GAGAGA');
              setTimeout(function(){
                widget.setSymbol('REND', '15');
                widget.setSymbol('REND', lastTime);
              }, 100);
            }
            blockClick = true;
            setTimeout(function(){
              blockClick = false;
            }, 1000);
          })
      }, 2000);

     /* var $this = this,
          intt = localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution');
      setTimeout(function(){
        widget.subscribe('series_properties_changed', (event) => { 
          //console.log('event', event);
          if(event == '_seriesId' && intt != interval) {
            $this.reinitNewChart();
          }
        });
      }, 2000);*/
      

    },
    getChart() {
      this.chart.feed = [];
      axios.post(this.$store._modules.root.state.main.API_URL+'/swap',
          {method: 'getChart', data: { symbol: this.symbol.coin, range: this.candlesRange, fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization': 'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);

        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), { timeout: 4000, progressBar: true });
        if (r.data.code !== 2 || typeof(r.data.data) !== 'object') return false;

        /*const newListData = [];
        for(const [key, value] of Object.entries(r.data.data.feed || [])) {
          newListData.push([value.time, value.open, value.high, value.low, value.close]);
        }

        const data = {
            "chart": {
                "data": newListData,
                "settings": {},
                "grid": {},
            },
        };
        this.tradingChart = new DataCube(data);
        this.$refs.tradingChart.resetChart(true);*/

        this.createNewChart();
        


        // this.chart.candles.setData(r.data.data.feed || []);
        // this.chart.app.timeScale().fitContent();
      });
    },
  },
  mounted() {
    this.tradeWidth = $('.content__column.content__column--candlechart').width();
    if (localStorage.glassFormat) {
      this.glassFormat = localStorage.glassFormat;
    }
    if (this.checkAuth()) {
      this.getCoins();
      this.getFeed();
      this.getLots();
      this.createChart();
      this.getBalance();
      this.checkAllowMarket();
    }

    // setTimeout(() => {
    //   this.tradingChart = new DataCube(Data);
    //   this.$refs.tradingChart.resetChart(true);
    // }, 5000);

  },
};
</script>

<style scoped>

  .overflow {
      overflow: hidden;
  }
  .box.tradehistory {
      transition: 300ms;
  }
  .box.tradehistory.full {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 999999999999;
      overflow: auto;
      top: 0; left: 0;
  }

</style>
