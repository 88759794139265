import { render, staticRenderFns } from "./SwapGlass.vue?vue&type=template&id=67c05062&scoped=true&"
import script from "./SwapGlass.vue?vue&type=script&lang=js&"
export * from "./SwapGlass.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c05062",
  null
  
)

export default component.exports