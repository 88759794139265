<template>
  <tr class="smart-table__row" @click="setLotFromGlass">
    <td class="smart-table__cell">
      <i v-if="mineLot"
         style="font-size: 9px; width: 6px; max-width: 6px; margin-right: 2px;"
         v-bind:class="{'text-danger': color === 'minus', 'text-success': color === 'plus',}"
         class="fas fa-chevron-right "></i>
      <i style="margin-left: 8px;" v-else></i>
      <span :class="color">{{price}}</span>
    </td>
    <td class="smart-table__cell text-right"><span>{{value}}</span></td>
    <td class="smart-table__cell text-right"><span>{{amount}}</span></td>
  </tr>
</template>

<script>
import {readUser} from '@/store/main/getters';

export default {
  name: 'SwapGlass',
  props: ['data', 'glassFormat'],
  data() {
    return {
      numberFormat: {
        '0.000001': 6,
        '0.00001': 5,
        '0.0001': 4,
        '0.001': 3,
        '0.01': 2,
      },
    }
  },
  computed: {
    user() {
      return readUser(this.$store);
    },
    value() {
      return (parseFloat(this.data.value)).toFixed(this.numberFormat[this.glassFormat]);
    },
    price() {
      return (parseFloat(this.data.price)).toFixed(this.numberFormat[this.glassFormat]);
    },
    amount() {
      return (parseFloat(this.data.amount)).toFixed(this.numberFormat[this.glassFormat]);
    },
    color() {
      return (parseInt(this.data.bid) === 0) ? 'minus' : 'plus';
    },
    mineLot() {
      if (this.user && this.user.uid) {
        return (this.data.uid).toString() === (this.user.uid).toString();
      } else {
        return false;
      }
    }
  },
  methods: {
    setLotFromGlass() {
      this.$parent.setLotFromGlass(this.price, this.value, this.amount);
    }
  }
}
</script>

<style scoped>

</style>
