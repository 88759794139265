<template>
  <tr class="smart-table__row">
    <td class="smart-table__cell"><span>
      <span v-if="market == 0">{{pair}}</span>
      <span v-if="market == 1">USDT/REND</span>
    </span></td>
    <td class="smart-table__cell"><span :class="color">{{type}}</span></td>
    <td class="smart-table__cell">
      <span v-if="market == 0">{{$t('limit')}}</span>
      <span v-if="market == 1">{{$t('market')}}</span>
    </td>
    <td class="smart-table__cell"><span>{{date_converted}}</span></td>
    <td class="smart-table__cell text-right">{{value}}</td>
    <td class="smart-table__cell text-right">{{commission}}</td>
    <td class="smart-table__cell text-right">{{price}}</td>
    <td class="smart-table__cell text-right">{{amount}}</td>
    <td class="smart-table__cell">
      <div v-if="closed">{{ closed }}</div>
      <div class="d-flex align-items-center justify-content-between" v-else>
        <k-progress :percent="0" status="error" style="width: 100%; height: 5px;"></k-progress>
        <span class="smart-table__close" @click="delSwap(data.id)"></span>
      </div>
    </td>
  </tr>
</template>

<script>
const cfg = {
  coin: 'REND',
  base: 'USDT',
};

export default {
  name: 'SwapLot',
  props: ['data'],
  computed: {
    pair() {
      return this.data.symbol+'/' + cfg.base;
    },
    type() {
      return (parseInt(this.data.bid) === 0) ? this.$t('ask') : this.$t('bid');
    },
    date_converted() {
      return this.stamp2date(this.data.stamp);
    },
    value() {
      return (parseFloat(this.data.value)).toFixed(6);
    },
    price() {
      return (parseFloat(this.data.price)).toFixed(6);
    },
    amount() {
      return (parseFloat(this.data.amount)).toFixed(6);
    },
    color() {
      return (parseInt(this.data.bid) === 0) ? 'minus' : 'plus';
    },
    closed() {
      return (Number(this.data.closed) !== 0) ? this.stamp2date(this.data.closed) : false;
    },
    market() {
      return this.data.market;
    },
    commission() {
      var commission = this.data.comission_usdt + ' USDT';
      if(parseInt(this.data.bid) === 1) commission = this.data.comission_rend + ' REND';
      return commission;
    },
  },
  methods: {
    delSwap() {
      this.$parent.delSwap(this.data.id);
    },
  },
}
</script>

<style scoped>

</style>
